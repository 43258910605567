import React, { useEffect, useCallback, useRef, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import NavBar from '../components/NavBar';
import RowItem from '../components/RowItem';
import { CompanyContext } from '../context/CompanyContext';
import { UserContext } from '../context/UserContext';
import { callGetScheduleSummary, callUpdateScheduleSummary, callCreateScheduleSummaryGalleryAsset, callDeleteScheduleSummaryGalleryAsset } from '../functions';
import LoadingIndicator from '../components/LoadingIndicator';
import './ScheduleExecutionScreen.css';
import { Box, TextField, Dialog, DialogTitle, List, ListItem, ListItemText, DialogContent, DialogActions, Button, Typography, Grid, Card, CardMedia } from '@mui/material';
import { FirebaseError } from 'firebase/app';

const ScheduleSummaryScreen = () => {
  const { selectedCompany } = useContext(CompanyContext);
  const user = useContext(UserContext);
  const [scheduleSummaryId, setScheduleSummaryId] = useState(useParams().scheduleSummaryId);
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [scheduleSummary, setScheduleSummary] = useState(location.state?.scheduleSummary);
  const [isLoading, setIsLoading] = useState(false);
  const [isNotesModalOpen, setIsNotesModalOpen] = useState(false);
  const [notes, setNotes] = useState(scheduleSummary?.notes || '');

  const MAX_NOTES_LENGTH = 500;

  const [gallery, setGallery] = useState(scheduleSummary?.gallery?.map(item => (
    {
      state: "existing",
      asset: item
    })) || []);
  const [galleryModalItem, setGalleryModalItem] = useState(null);

  useEffect(() => {
    if (!selectedCompany) { return }

    if (!scheduleSummaryId) {
      navigate('/reports');
    }

    const fetchScheduleSummary = async () => {
      setIsLoading(true);
      try {
        const fetchedScheduleSummary = await callGetScheduleSummary(scheduleSummaryId);
        setScheduleSummary(fetchedScheduleSummary);
      } catch (error) {
        console.error('Error fetching scheduleSummary:', error);
        localStorage.removeItem('scheduleSummary');
        setScheduleSummary(null);
        setScheduleSummaryId(null);
      } finally {
        setIsLoading(false);
      }
    };
    if (scheduleSummaryId !== scheduleSummary?.id) {
      fetchScheduleSummary();
    }
  }, [scheduleSummary, navigate, scheduleSummaryId, selectedCompany]);

  useEffect(() => {
    if (scheduleSummary) {
      localStorage.setItem('scheduleSummary', JSON.stringify(scheduleSummary));
    }
  }, [scheduleSummary]);

  const handleBack = () => {
    localStorage.removeItem('scheduleSummary');
    setScheduleSummary(null);
    setScheduleSummaryId(null);
  };

  const handleSave = async () => {
    // TODO: JD this should be removed by api;
    delete scheduleSummary._companyId_startDate;
    setIsLoading(true);
    const apiFunction = callUpdateScheduleSummary

    try {
      const updateScheduleSummary = await apiFunction(scheduleSummary);


      let hadErrors = false;
      let lastGalleryAssets = updateScheduleSummary.gallery
      for (const item of gallery) {
        if (item.state === 'deleted') {
          const asset = item.asset;
          try {
            await callDeleteScheduleSummaryGalleryAsset(updateScheduleSummary.id, asset.id);
          } catch (error) {
            console.error('Error deleting gallery asset:', error);
            hadErrors = true;
          }
        }

        if (item.state === 'new') {
          const asset = item.asset;
          try {
            lastGalleryAssets = await callCreateScheduleSummaryGalleryAsset(user.id, updateScheduleSummary.id, asset);
          } catch (error) {
            console.error('Error creating gallery asset:', error);
            hadErrors = true;
          }
        }
      };

      setIsLoading(false);
      if (hadErrors) {
        alert(t("error.savedWithErrors"));
        setScheduleSummary(null);
        setScheduleSummaryId(null);
        return;
      }
      if(lastGalleryAssets) {
        updateScheduleSummary.gallery = lastGalleryAssets;  
      } else {
        delete updateScheduleSummary.gallery;
      }

      setScheduleSummary(updateScheduleSummary);
      setScheduleSummaryId(updateScheduleSummary.id);
      alert(t('scheduleSummary.saved'));
    } catch (error) {
          if(error instanceof FirebaseError) {
            const message = error.details?.error?.message;
            //TODO: JD Translation
            alert(message);
          } else {
            alert(t("error.general"));
          }
        } finally {
          setIsLoading(false);
        }

  };

  const handleGalleryAssetClick = (item) => {
    setGalleryModalItem(item);
  };

  const onNotesClick = () => {
    setIsNotesModalOpen(true);
  };

  const handleNotesSave = () => {
    setScheduleSummary({ ...scheduleSummary, notes });
    setIsNotesModalOpen(false);
  };

  const resizeImage = (img, maxDimension) => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    let width = img.width;
    let height = img.height;
  
    if (width > height) {
      if (width > maxDimension) {
        height = Math.round((height *= maxDimension / width));
        width = maxDimension;
      }
    } else {
      if (height > maxDimension) {
        width = Math.round((width *= maxDimension / height));
        height = maxDimension;
      }
    }
  
    canvas.width = width;
    canvas.height = height;
    ctx.drawImage(img, 0, 0, width, height);
  
    return canvas.toDataURL('image/jpeg');
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file && file.type.startsWith('image/')) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.onload = () => {
          const thumbnailUrl = resizeImage(img, 200);
          const assetUrl = resizeImage(img, 1000);

          const newImage = {
            asset: {
              type: 'photo',
              thumbnailUrl: thumbnailUrl,
              assetUrl: assetUrl
            },
            state: 'new'
          };
          console.log('newImage', newImage);
          setGallery((prevGallery) => [...prevGallery, newImage]);
        };
        img.src = e.target.result;
      };
      reader.readAsDataURL(file);
    } else {
      console.error('The selected file is not an image.');
      //TODO: JD error handling
    }
  };

  return (
    <div className="scheduleSummary-details-container">
      <NavBar
        title={scheduleSummary?.schedule.name}
        onBackClick={handleBack}
        rightButtonTitle={t('general.save')}
        onRightButtonClick={handleSave}
      />
      {isLoading ? (
        <div className="loading-modal">
          <LoadingIndicator />
        </div>
      ) : (
        scheduleSummary && (
          <div className="scheduleSummary-details-content">
            <RowItem
              title={t('scheduleSummary.startTime')}
              secondaryTitle=""
              details={new Date(scheduleSummary.startDate * 1000).toLocaleString()}
              showDetailsIndicator={false}
              />
            <RowItem
              title={t('scheduleSummary.endTime')}
              secondaryTitle=""
              details={new Date(scheduleSummary.endDate * 1000).toLocaleString()}
              showDetailsIndicator={false}
              />
              <RowItem
              title={t('scheduleSummary.elapsedTime')}
              secondaryTitle=""
              //Format as hours and minutes
              details={new Date((scheduleSummary.endDate - scheduleSummary.startDate) * 1000).toISOString().substr(11, 8)}
              showDetailsIndicator={false}
              />
              <RowItem
              title={t('scheduleSummary.toDo')}
              secondaryTitle=""
              details={scheduleSummary.schedule.areasToDo?.length || 0}
              showDetailsIndicator={false}
              />
              <RowItem
              title={t('scheduleSummary.done')}
              secondaryTitle=""
              details={scheduleSummary.schedule.areasCompleted?.length || 0} 
              showDetailsIndicator={false}
              />
                          <RowItem
              title={t('scheduleSummary.notes')}
              secondaryTitle=""
              details=""
              secondaryDetails=""
              showDetailsIndicator={true}
              onClick={onNotesClick}
            />

              <div className="gallery-container">
                <Grid container spacing={4} sx={{ flexWrap: 'nowrap', overflowX: 'auto' }}>
                  {gallery
                    .filter(item => item.asset.type === 'photo')
                    .filter(item => item.state !== 'deleted')
                    .map((item, index) => (
                      <Grid item key={index} sx={{ flex: '0 0 auto' }}>
                        <Card
                          onClick={() => handleGalleryAssetClick(item)}
                          sx={{
                            width: 50,
                            height: 50,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                          }}
                        >
                          <CardMedia
                            component="img"
                            image={item.asset.thumbnailUrl}
                            alt={`Gallery item ${index + 1}`}
                            title={`Gallery item ${index + 1}`}
                            sx={{
                              maxWidth: '100%',
                              maxHeight: '100%',
                              objectFit: 'contain'
                            }}
                          />
                        </Card>
                      </Grid>
                    ))}
                  <Grid item sx={{ flex: '0 0 auto' }}>
                    <Card
                      sx={{
                        width: 50,
                        height: 50,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        cursor: 'pointer'
                      }}
                      onClick={() => document.getElementById('file-input').click()}
                    >
                      <Typography variant="h4" color="primary">+</Typography>
                      <input
                        type="file"
                        id="file-input"
                        style={{ display: 'none' }}
                        onChange={handleFileUpload}
                      />
                    </Card>
                  </Grid>
                </Grid>
              </div>

          </div>
        )



      )}

      <Dialog open={isNotesModalOpen} onClose={() => setIsNotesModalOpen(false)} maxWidth="lg" fullWidth>
              <DialogTitle>{t('scheduleSummary.editNotes')}</DialogTitle>
              <DialogContent>
                <TextField
                  label={t('scheduleSummary.notes')}
                  variant="outlined"
                  fullWidth
                  multiline
                  rows={10}
                  value={notes}
                  onChange={(e) => setNotes(e.target.value.slice(0, MAX_NOTES_LENGTH))} // Limit the length of notes
                  helperText={`${notes.length}/${MAX_NOTES_LENGTH}`}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setIsNotesModalOpen(false)} color="primary">
                  {t('general.cancel')}
                </Button>
                <Button onClick={handleNotesSave} color="primary">
                  {t('general.save')}
                </Button>
              </DialogActions>
            </Dialog>

      <Dialog
        open={galleryModalItem !== null}
        onClose={() => setGalleryModalItem(null)}
      >
        <DialogTitle>{t('galleryModal.title')}</DialogTitle>
        <DialogActions>
          <Box display="flex" flexDirection="column" width="100%">
            <Button onClick={() => {
              window.open(galleryModalItem.asset.assetUrl, '_blank');
              setGalleryModalItem(null);
            }}>
              {t('galleryModal.view')}
            </Button>
            <Button onClick={() => {
              if (galleryModalItem.state === 'existing') {
                setGallery(gallery.map(item => item === galleryModalItem ? { ...item, state: 'deleted' } : item));
              } else {
                setGallery(gallery.filter(item => item !== galleryModalItem));
              }
              setGalleryModalItem(null);
            }} color="secondary">
              {t('galleryModal.delete')}
            </Button>
            <Button onClick={() => setGalleryModalItem(null)} color="primary">
              {t('galleryModal.cancel')}
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </div>

  );

}

export default ScheduleSummaryScreen;
// src/screens/ReportsScreen.js
import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import NavBar from '../components/NavBar';
import './ReportsScreen.css';
import { callGetScheduleSummaries } from '../functions';
import LoadingIndicator from '../components/LoadingIndicator';
import RowItem from '../components/RowItem';
import { CompanyContext } from '../context/CompanyContext';
import SearchBar from '../components/SearchBar';

const ReportsScreen = () => {
  const { t } = useTranslation();
  const [searchText, setSearchText] = useState('');
  const [reports, setReports] = useState([]);
  const [loading, setLoading] = useState(false);
  const { selectedCompany } = useContext(CompanyContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (!selectedCompany) return;

    const fetchReports = async () => {
      setLoading(true);
      try {
        const oneWeekInSeconds = 7 * 24 * 60 * 60;
        const endTime = Math.floor(Date.now() / 1000);
        const startTime = endTime - oneWeekInSeconds;
        const response = await callGetScheduleSummaries(selectedCompany.id, startTime, endTime);
        setReports(response);
      } catch {
        alert('Error fetching reports'); // TODO: JD translation
      } finally {
        setLoading(false);
      }
    };

    fetchReports();
  }, [selectedCompany]);

  const filteredReports = reports.filter(report => {
    return report.property.name.toLowerCase().includes(searchText.toLowerCase()) ||
      report.schedule.name.toLowerCase().includes(searchText.toLowerCase()) ||
      report.schedule.department?.name.toLowerCase().includes(searchText.toLowerCase());
  }
  );

  const handleReportClick = (scheduleSummary) => {
    navigate(`/reports/${scheduleSummary.id}`, { state: { scheduleSummary } });
  };

  return (
    <div className="reports-container">
      <NavBar title={t("reports.title")} className="navbar" />
      <SearchBar
        placeholder={t('scheduleExecution.areaList.search')}
        value={searchText}
        onChange={e => setSearchText(e.target.value)}
      />
      {loading ? (
        <LoadingIndicator />
      ) : (
        <div className="reports-list">
          {filteredReports.map(report => (
            <RowItem
              key={report.id}
              title={report.property.name} // Placeholder for title
              secondaryTitle={report.schedule.name} // Placeholder for secondary title
              details={new Date(report.startDate * 1000).toLocaleString()} // Placeholder for details
              secondaryDetails={report.schedule.department?.name || ""} // Placeholder for secondary details
              // image={report.priority} // Placeholder for image //TODO: JD missing image
              onClick={() => handleReportClick(report)}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default ReportsScreen;